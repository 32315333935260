import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabContent", "nudgesContent"];

  connect() {
    this.updateUi(this.getRulesetId(), this.getCategoryId());
    this.observeTarget(this.tabContentTarget);
    this.observeTarget(this.nudgesContentTarget);
  }

  observeTarget(target) {
    // Create a MutationObserver instance
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.updateUi(this.getRulesetId(), this.getCategoryId());
        }
      }
    });

    // Configure the observer to watch for child nodes being added or removed
    observer.observe(target, {
      childList: true,
      subtree: true, // Set to true to observe the entire subtree within the target
    });

    // Store the observer instance if you need to disconnect it later
    this.observer = observer;
  }

  rulesetSelected(evt) {
    const ruleset = evt.target.value;
    const category = this.getCategoryId();

    this.updateUi(ruleset, category);
  }

  categorySelected(evt) {
    const category = evt.target.value;
    const ruleset = this.getRulesetId();

    this.updateUi(ruleset, category);
  }

  updateUi(ruleset, category) {
    this.updateContentVisibility(ruleset, category);
    this.updateLink(ruleset, category, document.getElementById("add-help-item"));
    this.updateLink(ruleset, category, document.getElementById("add-nudge"));
  }

  updateContentVisibility(ruleset, category) {
    // Hide the currently active ruleset
    const existingRuleset = this.tabContentTarget.querySelector(".ruleset-content.active");
    if (existingRuleset) {
      existingRuleset.classList.add("hidden");
      existingRuleset.classList.remove("active");
    }

    // Show the new ruleset content
    const newRuleset = document.getElementById(`ruleset-${ruleset}`);
    if (newRuleset) {
      newRuleset.classList.add("active");
      newRuleset.classList.remove("hidden");

      // Hide all help item category content within the new ruleset
      let activeHelpItems = newRuleset.querySelectorAll(".help-item-content.active");
      activeHelpItems.forEach(item => {
        item.classList.add("hidden");
        item.classList.remove("active");
      });

      // Show the selected category help item content
      let selectedCategoryItems = newRuleset.querySelectorAll(`.category-${category}`);
      selectedCategoryItems.forEach(item => {
        item.classList.remove("hidden");
        item.classList.add("active");
      });

      let activeNudges = this.nudgesContentTarget.querySelectorAll('.nudge-row')
      activeNudges.forEach(item => {
        item.classList.add("hidden");
        item.classList.remove("active");
      });

      let selectedCategoryNudge = this.nudgesContentTarget.querySelectorAll(`.ruleset-${ruleset}-category-${category}`);
      selectedCategoryNudge.forEach(item => {
        item.classList.remove("hidden");
        item.classList.add("active");
      });
    }
  }

  updateLink(ruleset, category, button) {
    let url = button.getAttribute("href");

    // Replace or add the ruleset_id and category_id in the URL
    url = url.replace(/(\?|&)ruleset_id=[^&]*/, '');
    url = url.replace(/(\?|&)category_id=[^&]*/, '');
    url += `?ruleset_id=${ruleset}&category_id=${category}`;

    button.setAttribute("href", url);
  }

  getRulesetId() {
    return document.getElementById('select-ruleset-id').value;
  }

  getCategoryId() {
    return document.getElementById('select-category-id').value;
  }
}
